import { ref, computed } from "@vue/composition-api";
export function usePasswordVisibility() {
  const passwordFieldType = ref("password");
  const passwordToggleIcon = computed(() => {
    return passwordFieldType.value === "password" ? "EyeIcon" : "EyeOffIcon";
  });
  function togglePasswordVisibility() {
    passwordFieldType.value =
      passwordFieldType.value === "password" ? "text" : "password";
    
  }
  return {
    passwordFieldType,
    togglePasswordVisibility,
    passwordToggleIcon,
  };
}
